import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { DatePicker, Table } from "antd";
import "./tickets.css";

import edit from "../../Assests/images/edit.png";
import del from "../../Assests/images/delete.png";

import { baseUrl } from "../../utils/axios";
import instance from "../../utils/axios";
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Drawer,
  message,
  Spin,
  Upload,
} from "antd";
import { Option } from "antd/es/mentions";
import { Link, useLocation } from "react-router-dom";
import AppContext from "./../../AppContext";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import {
  DownloadOutlined,
  ExportOutlined,
  FlagOutlined,
  DeleteOutlined,
  SearchOutlined,
  InboxOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

const { Dragger } = Upload;

function Index() {
  const {
    contact,
    group,
    agent,
    status,
    priority,
    getAllContacts,
    getAllCompanies,
    // getAllGroups,
    getAllAgents,
    contract,
  } = useContext(AppContext);
  // console.log(group, "group context");

  const [form] = Form.useForm();
  const [ticket, setTicket] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [record, setRecord] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [ticket_id, setTicketId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const navigate = useNavigate();
  const [subjectError, setSubjectError] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [ticketGroup, setTicketGroup] = useState([]);
  const [editTicketData, setEditTicketData] = useState({
    agent: "",
    company: "",
    contact: "",
    contact_email: "",
    contact_work_phone: "",
    custom_ticket_type: "",
    description: "",
    email: "",
    group: "",
    impact: "",
    priority: "",
    product: "",
    source: "",
    status: "",
    subject: "",
    contract_name: "",
    contract: "",
    ticket_close_date: "",
    id: "",
  });
  const dateFormat = "YYYY-MM-DD HH:mm:ss";

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };
  const handleOpenChange = (open) => {
    if (open) {
      setTimeout(() => {
        const calendar = document.querySelector(".ant-picker-dropdown");
        if (calendar) {
          const prevMonthButton = calendar.querySelector(
            ".ant-picker-header-prev-btn"
          );
          const prevYearButton = calendar.querySelector(
            ".ant-picker-header-super-prev-btn"
          );
          if (prevMonthButton) {
            prevMonthButton.style.display = "none";
          }
          if (prevYearButton) {
            prevYearButton.style.display = "none";
          }
        }
      }, 0);
    }
  };
  const handleDateChange = (date) => {
    setEditTicketData({
      ...editTicketData,
      ticket_close_date: date ? date.format(dateFormat) : "",
    });
  };

  const today = new Date();
  const startOfToday = new Date(today.setHours(0, 0, 0, 0)).toISOString();
  const startOfWeek = new Date(
    today.setDate(today.getDate() - today.getDay())
  ).toISOString();
  const startOfMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  ).toISOString();
  const startOfYear = new Date(today.getFullYear(), 0, 1).toISOString();

  useEffect(() => {
    getAllContacts();
    getAllCompanies();
    // getAllGroups();
    getAllAgents();
  }, []);

  const LoadingIndicator = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <LoadingOutlined
        style={{
          fontSize: 24,
          color: "#229849",
        }}
        spin
      />
    </div>
  );

  useEffect(() => {
    if (record) {
      setEditTicketData({
        ...editTicketData,
        ticket_id: record.ticket_id,
      });
    }
  }, [record]);

  const handlecreateticket = () => {
    getAllContacts();
    getAllCompanies();
    // getAllGroups();
    getAllAgents();
  };

  const location = useLocation();

  const { data, stateData } = location.state || {};

  const getTickets = async () => {
    setLoading(true);
    await instance
      .get(baseUrl + "/getAlltickets")
      .then((res) => {
        if (res) {
          setTicket(res.data);
          setLoading(false);
          setTicketGroup(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const deleteTicket = async (ticket_id) => {
    if (ticket_id) {
      try {
        const response = await instance.delete(
          baseUrl + `/deleteTicket/${ticket_id}`
        );
        if (response) {
          message.success(response.data.message);
        }
        getTickets();
      } catch (error) {
        console.error("Error deleting ticket:", error);
      }
    } else {
      Swal.fire({
        title: "Please! select a ticket",
        icon: "error",
        confirmButtonText: "OK",
        timer: 2000,
        timerProgressBar: true,
      });
    }
  };

  const deleteTickets = async () => {
    try {
      const response = await instance.delete(
        baseUrl + `/delete_multiple_tickets?ids=${selectedRowKeys}`
      );
      if (response) {
        message.success(response.data.message);
        setSelectedRowKeys([]);
      }
      getTickets();
    } catch (error) {
      console.error("Error deleting ticket:", error);
    }
  };

  const editTicket = async (ticket_id) => {
    setIsLoading(true);

    const requestData = {
      subject: editTicketData.subject,
      description: editTicketData.description,
      // contract_id: editTicketData.contract,
      contact: editTicketData.contact,
      contact_email: editTicketData.contact_email,
      email: editTicketData.email,
      group: editTicketData.group,
      priority: editTicketData.priority,
      status: editTicketData.status,
      source: editTicketData.source,
      agent: editTicketData.agent,
    };

    // if (editTicketData.ticket_close_date) {
    //   requestData.ticket_close_date = editTicketData.ticket_close_date;
    // }

    if (editTicketData.status === "Closed") {
      requestData.ticket_close_date = dayjs().format("YYYY-MM-DD HH:mm:ss");
    } else if (editTicketData.ticket_close_date) {
      requestData.ticket_close_date = dayjs(
        editTicketData.ticket_close_date
      ).format("YYYY-MM-DD HH:mm:ss");
    }
    await instance
      .put(`${baseUrl}/editTicket/${ticket_id}`, requestData)
      .then((res) => {
        setSelectedTicket(false);
        getTickets();
        setSubjectError("");
      })
      .catch((err) => console.log(err));

    setIsLoading(false);
  };

  const handleEditClick = (record) => {
    const agentId = agent
      ? agent.filter((agen) => agen.name == record.agent)
      : [];

    setSelectedTicket(record);

    const customerId = group
      ? group.filter((customer) => customer.group_name == record.group)
      : [];

    const contactId = contact
      ? contact.filter((customer) => customer.vendor_name == record.contact)
      : [];

    setEditTicketData({
      ...record,
      agent: agentId.length > 0 && agentId[0].user_id,
      // group: customerId[0].group_id,
      contact: contactId.length > 0 && contactId[0].vendor_id,
      contract: record.contract_name,
      ticket_close_date: record.ticket_close_date,
    });
  };

  const onFinishEdit = async (values) => {
    try {
      await editTicket(selectedTicket.id, values);
      Swal.fire({
        title: "updated successfully",
        icon: "success",
        confirmButtonText: "OK",
      });
      form.resetFields();
      setSelectedTicket("");
    } catch (error) {
      console.error("Error editing ticket:", error);
    }
  };

  useEffect(() => {
    getTickets();
    getTicketStatusCard();
  }, []);

  const [ticketCount, setTicketCount] = useState([]);

  const getTicketStatusCard = async () => {
    try {
      const response = await instance.get(baseUrl + "/getTicketCountPieGraph");

      setTicketCount(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const showModal = () => {
    setOpen2(true);
  };

  const handleCancel = () => {
    setOpen2(false);
  };

  const commonColumns = [
    {
      title: <span className="table-title">Serial No</span>,
      width: 100,
      dataIndex: "serial_no",
      key: "serial_no",
      sorter: (a, b) => a.serial_no.localeCompare(b.serial_no),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            className="filter_input"
            placeholder="Search Serial No"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.serial_no
          ? record.serial_no
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : false;
      },
    },
    {
      title: <span className="table-title">Customer Name</span>,
      width: 100,
      dataIndex: "contact",
      key: "contact",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.contact.localeCompare(b.contact),
      render: (name, record) => (
        <a className="contact-column" onClick={() => onRowClick(record)}>
          {name}
        </a>
      ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Select
            style={{ width: 188, marginBottom: 10, display: "block" }}
            placeholder="Select Customer Name"
            value={selectedKeys[0]}
            onChange={(value) => {
              setSelectedKeys(value ? [value] : []);
              confirm();
            }}
            allowClear
          >
            {contact.map((contact) => (
              <Option key={contact.vendor_name} value={contact.vendor_name}>
                {contact.vendor_name}
              </Option>
            ))}
          </Select>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              backgroundColor: "#229849",
              color: "white",
            }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) => record.contact === value,
    },
    {
      title: <span className="table-title">Assigned To</span>,
      width: 100,
      dataIndex: "agent",
      key: "agent",
      sorter: (a, b) => a.agent.localeCompare(b.agent),
      render: (assign) => <p className="assign-option">{assign}</p>,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Select
            style={{ width: 188, marginBottom: 10, display: "block" }}
            placeholder="Select Agent"
            value={selectedKeys[0]}
            onChange={(value) => {
              setSelectedKeys(value ? [value] : []);
              confirm();
            }}
            allowClear
          >
            {agent.map((agent) => (
              <Option key={agent.name} value={agent.name}>
                {agent.name}
              </Option>
            ))}
          </Select>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              backgroundColor: "#229849",
              color: "white",
            }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) => record.agent === value,
    },

    {
      title: <span className="table-title">Contract Name</span>,
      width: 100,
      dataIndex: "contract_name",
      key: "contract_name",
      sorter: (a, b) => a.contract_name.localeCompare(b.contract_name),
      // render: (contract_name, record) => (
      //   <a className="contact-column" onClick={() => onRowClick(record)}>
      //     {contract_name.substring(0, 20)}
      //   </a>
      // ),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Select
            style={{ width: 188, marginBottom: 10, display: "block" }}
            placeholder="Select Customer Name"
            value={selectedKeys[0]}
            onChange={(value) => {
              setSelectedKeys(value ? [value] : []);
              confirm();
            }}
            allowClear
          >
            {contract.map((contract) => (
              <Option
                key={contract.contract_name}
                value={contract.contract_name}
              >
                {contract.contract_name}
              </Option>
            ))}
          </Select>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              backgroundColor: "#229849",
              color: "white",
            }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) => record.contract_name === value,
    },
    {
      title: <span className="table-title">Customer</span>,
      width: 100,
      dataIndex: "group",
      key: "group",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.group.localeCompare(b.group),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Select
            style={{ width: 188, marginBottom: 10, display: "block" }}
            placeholder="Select Customer Name"
            value={selectedKeys[0]}
            onChange={(value) => {
              setSelectedKeys(value ? [value] : []);
              confirm();
            }}
            allowClear
          >
            {ticketGroup.map((group) => (
              <Option key={group.group} value={group.group}>
                {group.group}
              </Option>
            ))}
          </Select>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{
              width: 90,
              marginRight: 8,
              backgroundColor: "#229849",
              color: "white",
            }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value, record) => record.group === value,
    },
    {
      title: <span className="table-title">Status</span>,
      width: 100,
      dataIndex: "status",
      key: "status",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.status.localeCompare(b.status),
      filters: [
        {
          text: "Open",
          value: "Open",
        },
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Resolved",
          value: "Resolved",
        },
        {
          text: "Closed",
          value: "Closed",
        },
        {
          text: "Scheduled",
          value: "Scheduled",
        },
        {
          text: "RMA",
          value: "RMA",
        },
        {
          text: "Waiting on customer",
          value: "Waiting on customer",
        },
        {
          text: "Waiting on vendor",
          value: "Waiting on vendor",
        },

        {
          text: "Recorded",
          value: "Recorded",
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => record.status.startsWith(value),
      render: (record) => {
        return (
          <>
            {record == "Resolved" ? (
              <div className="status_resolved">{record}</div>
            ) : record == "Open" ? (
              <div className="status_open">{record}</div>
            ) : record == "open" ? (
              <div className="status_open">{record}</div>
            ) : record == "Closed" ? (
              <div className="status_closed">{record}</div>
            ) : record == "RMA" ? (
              <div className="status_rma">{record}</div>
            ) : record == "Pending" ? (
              <div className="status_pending">{record}</div>
            ) : record == "Waiting on customer" ? (
              <div className="status_Waiting_on_customer">{record}</div>
            ) : record == "Waiting on vendor" ? (
              <div className="status_Waiting_on_vendor">{record}</div>
            ) : record == "Scheduled" ? (
              <div className="status_Scheduled">{record}</div>
            ) : (
              <div className="status_overdue">{record}</div>
            )}
          </>
        );
      },
    },
    {
      title: <span className="table-title">Priority</span>,
      width: 100,
      dataIndex: "priority",
      key: "priority",
      // defaultSortOrder: "ascend",
      render: (priority) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            color:
              priority == "Medium"
                ? "#1EB2F1"
                : priority == "Low"
                ? "#76BA1B"
                : priority == "Urgent"
                ? "#DA0404"
                : "#FF7C02",
          }}
        >
          <FlagOutlined />
          <p style={{ margin: 0 }}>{priority}</p>
        </div>
      ),
      sorter: (a, b) => a.priority.localeCompare(b.priority),
      filters: [
        {
          text: "Low",
          value: "Low",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "High",
          value: "High",
        },
        {
          text: "Urgent",
          value: "Urgent",
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => record.priority.startsWith(value),
    },

    {
      title: <span className="table-title">Subject</span>,
      width: 100,
      dataIndex: "subject",
      key: "subject",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            className="filter_input"
            placeholder="Search Subject"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.subject
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    },

    {
      title: <span className="table-title">Created At</span>,
      width: 100,
      dataIndex: "creation_date",
      key: "creation_date",
      sorter: (a, b) => new Date(a.creation_date) - new Date(b.creation_date),
      render: (date) => dayjs(date).format("DD-MM-YYYY"),

      filters: [
        {
          text: "Today",
          value: "Today",
        },
        {
          text: "Week",
          value: "Week",
        },
        {
          text: "Month",
          value: "Month",
          children: Array.from({ length: 12 }, (_, index) => ({
            text: dayjs()
              .month(index)
              .format("MMMM"),
            value: index,
          })),
        },
        {
          text: "Year",
          value: "Year",
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => {
        const recordDate = new Date(record.creation_date);
        const startOfToday = new Date().setHours(0, 0, 0, 0);
        const startOfWeek = new Date();
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
        const startOfMonth = new Date();
        startOfMonth.setDate(1);
        const startOfYear = new Date(new Date().getFullYear(), 0, 1);

        switch (value) {
          case "Today":
            return recordDate >= startOfToday;
          case "Week":
            return recordDate >= startOfWeek;
          case "Month":
            return recordDate >= startOfMonth;
          case "Year":
            return recordDate >= startOfYear;
          default:
            if (typeof value === "number") {
              return recordDate.getMonth() === value;
            }
            return true;
        }
      },
    },

    {
      title: <span className="table-title">Created Time</span>,
      width: 100,
      dataIndex: "creation_time",
      key: "creation_time",
      sorter: (a, b) => a.creation_time.localeCompare(b.creation_time),
    },
    {
      title: <span className="table-title">Closed Date</span>,
      width: 100,
      dataIndex: "ticket_close_date",
      key: "ticket_close_date",
      sorter: (a, b) =>
        new Date(a.ticket_close_date) - new Date(b.ticket_close_date),
      render: (date) => (date ? dayjs(date).format("DD-MM-YYYY") : ""),
    },
    {
      title: <span className="table-title">Closed Time</span>,
      width: 100,
      dataIndex: "ticket_close_date",
      sorter: (a, b) =>
        new Date(a.ticket_close_date) - new Date(b.ticket_close_date),
      render: (date) => (date ? dayjs(date).format("HH:mm:ss") : ""),
    },
    {
      title: <span className="table-title">SLA Time</span>,
      width: 100,
      dataIndex: "sla_time",
    },
    {
      title: <span className="table-title">SLA Status</span>,
      width: 100,
      dataIndex: "violate_status",
      render: (text) => (
        <span
          style={{
            backgroundColor: text === "Violated" ? "#FF9999" : "#A6F1A6",
            padding: "3px 8px",
            borderRadius: "4px",

            color: "white",
          }}
        >
          {text}
        </span>
      ),
    },
  ];

  const adminColumns = [
    {
      title: (
        <span
          className="table-action"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Action
        </span>
      ),
      width: 60,
      key: "action",

      fixed: "right",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            cursor: "pointer",
          }}
        >
          <div>
            {" "}
            <img
              src={edit}
              width={14}
              height={14}
              onClick={() => {
                handleEditClick(record);
                setRecord(record);
                handlecreateticket();
                getAllAgents();
              }}
            />
          </div>
          <div>
            {" "}
            <img
              src={del}
              width={12}
              height={15}
              onClick={() => {
                Modal.confirm({
                  title: "Confirm Delete",
                  content: "Are you sure you want to delete this ticket?",
                  okText: "Delete",
                  cancelText: "Cancel",
                  onOk: () => {
                    deleteTicket(record.ticket_id);
                  },
                });
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  const userColumns = [
    {
      title: (
        <span
          className="table-action"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Action
        </span>
      ),
      width: 60,
      key: "action",

      fixed: "right",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            cursor: "pointer",
          }}
        >
          <div>
            {" "}
            <img
              src={edit}
              width={14}
              height={14}
              onClick={() => {
                handleEditClick(record);
                setRecord(record);
                handlecreateticket();
                getAllAgents();
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    // Check if the stored role is "Admin"
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);

  useEffect(() => {
    // Check if the stored role is "Admin"
    setShowCreateTicketButton(userRole === "Admin" || userRole === "User");
  }, [userRole]);

  const columns = [
    ...commonColumns,
    ...(userRole === "Admin" ? adminColumns : []),
    ...(userRole === "User" ? userColumns : []),
  ];

  const handleInput = (e) => {
    const { name, value } = e.target;

    if (name === "subject") {
      if (value.trim() === "") {
        setSubjectError(
          "Your subject is blank. It will take the previous value by default."
        );
      } else {
        setSubjectError("");
      }
    }
    setEditTicketData({
      ...editTicketData,
      [name]: value,
    });
  };

  const handleSelectEdit = (key, value) => {
    setEditTicketData((editTicketData) => ({
      ...editTicketData,
      [key]: value,
    }));
  };

  // toggle sidebar
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("right");

  const onClose = () => {
    setOpen(false);
    setSubjectError("");
  };

  const [selectedContract, setSelectedContract] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPriority, setSelectedPriority] = useState("");

  const [filteredResults, setFilteredResults] = useState([]);

  useEffect(() => {
    if (data) {
      setFilteredResults(ticket.filter((item) => item.status == data));
    }
  }, [data, ticket]);
  useEffect(() => {
    // console.log("this is table data", filteredResults);
  }, [filteredResults]);

  const handleApplyFilter = () => {
    if (selectedContract) {
      filteredResults = filteredResults.filter(
        (item) => item.contact === selectedContract
      );
    }

    if (selectedStatus) {
      filteredResults = filteredResults.filter(
        (item) => item.status === selectedStatus
      );
    }

    if (selectedPriority) {
      filteredResults = filteredResults.filter(
        (item) => item.priority === selectedPriority
      );
    }
  };
  // Event handler for the "Reset Filter" button
  const handleResetFilter = () => {
    // Reset all selected filter options and clear the filtered data
    setSelectedContract("");
    setSelectedStatus("");
    setSelectedPriority("");
  };

  const onRowClick = async (record) => {
    try {
      const response = await instance.get(
        baseUrl + `/TicketHistory/${record.ticket_id}`
      );

      if (response && response.data !== "No ticket record found") {
        // setHistory(response.data);
        navigate(`/tickets/${record.ticket_id}`);
      } else {
        message.error("Record does not exist");
      }
    } catch (error) {
      console.log(error);
      message.error("Error occurred while fetching record");
    }
  };

  const handletwo = () => {
    setSelectedTicket(null);
    setSubjectError("");
  };

  const storedRole = localStorage.getItem("role");

  const [showCreateTicketButton, setShowCreateTicketButton] = useState(false);

  useEffect(() => {
    setShowCreateTicketButton(storedRole == "Admin" || storedRole == "User");
  }, [storedRole]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
    onSelect: (record) => {
      setTicketId((prevTicketIds) => [...prevTicketIds, record.ticket_id]);
    },
  };

  const Start = async () => {
    if (fileList.length == "0") {
      Swal.fire({
        title: "please! upload a file",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#229849",

        timerProgressBar: true,
      });
    } else {
      setLoading(true);
      const fileData = csvData.map((data) => data.contract);

      Swal.fire({
        title: "Ticket added Sucessfully",
        icon: "success",
        confirmButtonText: "OK",
        timer: 2000,
        timerProgressBar: true,
        confirmButtonColor: "#229849",
      });
      getTickets();

      setLoading(false);
      setOpen2(false);
    }
  };

  const handleUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const rows = text.split("\n");

      // Extract keys from the first row
      const keys = rows[0].split(",");

      const data = [];
      for (let i = 1; i < rows.length; i++) {
        const row = rows[i].trim();
        if (row) {
          const values = row.split(",");
          const obj = {};
          keys.forEach((key, index) => {
            obj[key.trim()] = values[index] ? values[index].trim() : "";
          });
          data.push(obj);
        }
      }

      setCsvData(data);
    };
    reader.readAsText(file);
  };

  const props = {
    name: "file",
    multiple: false, // Allow only one file to be uploaded at a time
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info) {
      const allowedExtensions = [".csv", ".xls", ".xlsx"];
      const extension = info.file.name.split(".").pop();
      const isAllowed = allowedExtensions.includes(
        "." + extension.toLowerCase()
      );
      if (!isAllowed) {
      } else {
        let newFileList = [...info.fileList];
        newFileList = newFileList.slice(-1);

        // 2. Read from response and show file link
        newFileList = newFileList.map((file) => {
          if (file.response) {
            // Component will show file.url as link
            file.url = file.response.url;
          }
          return file;
        });
        setFileList(newFileList);
      }
    },

    beforeUpload: (file) => {
      const allowedExtensions = [".csv", ".xls", ".xlsx"];
      const extension = file.name.split(".").pop();
      const isAllowed = allowedExtensions.includes(
        "." + extension.toLowerCase()
      );
      if (!isAllowed) {
        message.error(`File type not supported: ${extension}`);
      } else {
        handleUpload(file);
      }
      return isAllowed;
    },
  };

  const exportToCSV = () => {
    // If no data exists at all
    if (!ticket || ticket.length === 0) {
      message.error("No tickets available to export");
      return;
    }

    // Determine which data to export based on selection
    let dataToExport = [];
    if (selectedRowKeys && selectedRowKeys.length > 0) {
      // Export only selected rows
      dataToExport = ticket.filter((item) =>
        selectedRowKeys.includes(item.ticket_id)
      );
    } else {
      // Export all data when no rows are selected
      dataToExport = ticket;
    }

    // If filtered data is empty after selection
    if (dataToExport.length === 0) {
      message.error("No selected tickets to export");
      return;
    }

    // Convert data to CSV format
    const csvData = dataToExport.map((item) => ({
      Contact: item.contact || "",
      Subject: item.subject || "",
      Contract_Name: item.contract_name || "",
      Customer: item.group || "",
      Status: item.status || "",
      Priority: item.priority || "",
      Assigned_To: item.agent || "",
      Creation_Date: item.creation_date
        ? dayjs(item.creation_date).format("DD-MM-YYYY")
        : "",
      Creation_Time: item.creation_time || "",
      Close_Date: item.ticket_close_date
        ? dayjs(item.ticket_close_date).format("DD-MM-YYYY")
        : "",
      Close_Time: item.ticket_close_date
        ? dayjs(item.ticket_close_date).format("HH:mm:ss")
        : "",
    }));

    // Generate CSV content
    const csvContent =
      "data:text/csv;charset=utf-8," +
      Object.keys(csvData[0]).join(",") +
      "\n" +
      csvData.map((row) => Object.values(row).join(",")).join("\n");

    // Create and trigger download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "tickets.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up
  };

  return (
    <>
      <Modal
        open={open2}
        title="Import file"
        // onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" loading={loading} onClick={Start}>
            Submit
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <Dragger {...props} fileList={fileList}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ color: "#229849" }} />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">File Type: csv, excel</p>
          </Dragger>
        </Spin>
      </Modal>
      <Drawer
        title="Filter"
        placement={placement}
        closable={false}
        onClose={onClose}
        open={open}
        key={placement}
      >
        <div
          style={{
            padding: "5px 0px 5px 0px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <label className="label" htmlFor="type">
            Contract
          </label>
          <Select
            onChange={(value) => setSelectedContract(value)}
            style={{ width: 320 }}
            value={selectedContract}
          >
            {contact.map((company) => (
              <Option key={company.first_name} value={company.first_name}>
                {company.first_name}
              </Option>
            ))}
          </Select>

          <label className="label">Status</label>
          <Select
            placeholder="Select"
            style={{ width: 320 }}
            onChange={(value) => setSelectedStatus(value)}
            value={selectedStatus}
          >
            <Option value="Open">Open</Option>
            <Option value="Pending">Pending</Option>
            <Option value="RMA">RMA</Option>
            <Option value="RMA">Waiting on customer</Option>
            <Option value="RMA">Waiting on vendor</Option>
          </Select>

          <label className="label">Priority</label>
          <Select
            placeholder="Select"
            style={{ width: 320 }}
            onChange={(value) => setSelectedPriority(value)}
            value={selectedPriority}
          >
            <Option value="Low">Low</Option>
            <Option value="Medium">Medium</Option>
            <Option value="High">High</Option>
            <Option value="Urgent">Urgent</Option>
          </Select>

          <Button
            style={{ width: 320 }}
            onClick={handleApplyFilter}
            className="filter-button"
          >
            Apply Filter
          </Button>

          <Button style={{ width: 320 }} onClick={handleResetFilter}>
            Reset Filter
          </Button>
        </div>
      </Drawer>
      <div>
        <Modal
          style={{
            top: "20px",
          }}
          title="Edit Ticket"
          visible={selectedTicket}
          onCancel={handletwo}
          footer={null}
        >
          <Form
            form={form}
            onFinish={onFinishEdit}
            initialValues={selectedTicket}
          >
            <form style={{ flexBasis: "50%" }}>
              {userRole === "Admin" && (
                <div>
                  <div className="input">
                    <label className="label">
                      Customer Name<span>*</span>
                    </label>
                    <Select
                      value={editTicketData ? editTicketData.contact : ""}
                      onChange={(value) => handleSelectEdit("contact", value)}
                      style={{
                        width: "100%",
                      }}
                      options={contact.map((group) => ({
                        value: group.vendor_id,
                        label: group.vendor_name,
                      }))}
                    />
                  </div>

                  <div className="input">
                    <label className="label">
                      Contract Name<span>*</span>
                    </label>
                    <Select
                      value={editTicketData ? editTicketData.contract : ""}
                      onChange={(value) => handleSelectEdit("contract", value)}
                      style={{
                        width: "100%",
                      }}
                      options={
                        contract.length !== 0
                          ? contract.map((contract) => ({
                              value: contract.contract_id,
                              label: contract.contract_name,
                            }))
                          : []
                      }
                    />
                  </div>

                  <div className="input">
                    <label className="label" htmlFor="type">
                      Customer<span>*</span>
                    </label>

                    <Input
                      style={{ marginTop: "8px" }}
                      type="text"
                      id="subject"
                      name="group"
                      value={editTicketData.group}
                      onChange={handleInput}
                    />
                    {/* <Select
                      value={editTicketData ? editTicketData.group : ""}
                      onChange={(value) => handleSelectEdit("group", value)}
                      style={{
                        width: "100%",
                      }}
                      options={group.map((group) => ({
                        value: group.group_id,
                        label: group.group_name,
                      }))}
                    /> */}
                  </div>

                  <div className="edit-input" style={{ paddingBottom: "15px" }}>
                    <label className="label" htmlFor="subject">
                      Subject
                    </label>
                    <Input
                      value={editTicketData && editTicketData.subject}
                      name="subject"
                      onChange={handleInput}
                    />
                    {subjectError && (
                      <p
                        className="error-message"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          padding: "0px",
                        }}
                      >
                        Your subject is blank. It will be auto considered as the
                        last subject.
                      </p>
                    )}
                  </div>
                </div>
              )}
              {userRole === "Admin" && (
                <div>
                  <div className="input">
                    <label className="label" htmlFor="type">
                      Status
                    </label>
                    {isLoading && (
                      <Spin
                        tip="Submitting..."
                        size="large"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "white",
                        }}
                      />
                    )}

                    <Select
                      value={editTicketData && editTicketData.status}
                      name="status"
                      onChange={(value) => handleSelectEdit("status", value)}
                      style={{
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      {status.map((option) => (
                        <Option
                          style={{
                            marginBottom: "5px",
                            backgroundColor:
                              option.value == "Resolved"
                                ? "#D5E4D6"
                                : option.value == "Closed"
                                ? "#E8F2E6"
                                : option.value == "Open"
                                ? "#E6F3D4"
                                : option.value == "Pending"
                                ? "#FFEEEE"
                                : option.value == "RMA"
                                ? "#FFF6E5"
                                : option.value == "Waiting on customer"
                                ? "#E2DDD4"
                                : option.value == "Waiting on vendor"
                                ? "#F0ECD8"
                                : option.value == "Scheduled"
                                ? "#EFE4DB"
                                : option.value == "Recorded"
                                ? "#EAE4E4"
                                : "null",
                            color:
                              option.value == "Resolved"
                                ? "#2D482F"
                                : option.value == "Closed"
                                ? "#68BB59"
                                : option.value == "Open"
                                ? "#68BB59"
                                : option.value == "Pending"
                                ? "#DA0404"
                                : option.value == "RMA"
                                ? "#EB5757"
                                : option.value == "Waiting on customer"
                                ? "#FFA500"
                                : option.value == "Waiting on vendor"
                                ? "#C9BD83"
                                : option.value == "Scheduled"
                                ? "#FF7C02"
                                : option.value == "Recorded"
                                ? "#6B6B6B"
                                : "null",
                          }}
                          key={option.value}
                          value={option.value}
                        >
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>

                  <div className="input">
                    <label className="label" htmlFor="type">
                      Priority{" "}
                    </label>
                    <Select
                      value={editTicketData && editTicketData.priority}
                      name="priority"
                      onChange={(value) => handleSelectEdit("priority", value)}
                      style={{
                        width: "100%",
                      }}
                    >
                      {priority.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
              <div>
                <div className="input">
                  <label className="label" htmlFor="type">
                    Assigned To
                  </label>
                  {isLoading && (
                    <Spin
                      tip="Submitting..."
                      size="large"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "white",
                      }}
                    />
                  )}
                  <Select
                    value={editTicketData && editTicketData.agent}
                    name="agent"
                    onChange={(value) => handleSelectEdit("agent", value)}
                    style={{
                      width: "100%",
                    }}
                    options={agent.map((agent) => ({
                      value: agent.user_id,
                      label: agent.name,
                    }))}
                  />
                </div>

                {userRole === "Admin" && (
                  <div className="edit-input" style={{ paddingBottom: "15px" }}>
                    <label className="label" htmlFor="subject">
                      Description
                    </label>
                    <Input
                      value={editTicketData && editTicketData.description}
                      name="comment"
                      placeholder="add comment"
                      onChange={(e) =>
                        setEditTicketData({
                          ...editTicketData,
                          description: e.target.value,
                        })
                      }
                    />
                    {subjectError && (
                      <p
                        className="error-message"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          padding: "0px",
                        }}
                      >
                        Your subject is blank. It will be auto considered as the
                        last subject.
                      </p>
                    )}
                  </div>
                )}
                {userRole === "Admin" && (
                  <div className="edit-input" style={{ paddingBottom: "15px" }}>
                    <label className="label" htmlFor="subject">
                      Closed Date
                    </label>
                    <DatePicker
                      style={{
                        marginTop: "8px",
                      }}
                      placeholder="Select due date"
                      className="ticket-dueDate"
                      disabledDate={disabledDate}
                      onOpenChange={handleOpenChange}
                      type="text"
                      id="ticket_close_date"
                      name="ticket_close_date"
                      showTime
                      format={dateFormat}
                      // format="YYYY-MM-DD"
                      value={
                        editTicketData && editTicketData.ticket_close_date
                          ? dayjs(editTicketData.ticket_close_date)
                          : null
                      }
                      onChange={handleDateChange}
                    />
                    {subjectError && (
                      <p
                        className="error-message"
                        style={{
                          color: "red",
                          fontSize: "10px",
                          padding: "0px",
                        }}
                      >
                        Plz select date
                      </p>
                    )}
                  </div>
                )}
              </div>
            </form>
          </Form>
          <div style={{ textAlign: "right" }}>
            <Button
              style={{
                marginTop: "0px",
                position: "relative",
                backgroundColor: "#229849",
                color: "white",
              }}
              type="primary"
              htmlType="submit"
              onClick={() => editTicket(editTicketData.ticket_id)}
            >
              Update
            </Button>
          </div>
        </Modal>
      </div>
      <div
        style={{
          width: "92vw",

          marginBottom: "10px",
        }}
      >
        {localStorage.getItem("role_id") !== "User" ? (
          <div className="ticket_table_btns" style={{ paddingLeft: "25px" }}>
            {selectedRowKeys && selectedRowKeys.length > 0 ? (
              <Button
                className="delete_btn"
                icon={<DeleteOutlined />}
                onClick={deleteTickets}
              >
                Delete
              </Button>
            ) : (
              <div></div>
            )}
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <Button
                onClick={exportToCSV}
                className="export_btn"
                icon={<ExportOutlined />}
              >
                Export
              </Button>
              <Button
                className="export_btn"
                icon={<DownloadOutlined />}
                onClick={showModal}
              >
                Import
              </Button>
              {showCreateTicketButton && (
                <Link to="/Newticket" style={{ textDecoration: "none" }}>
                  <Button
                    className="create_btn"
                    type="submit"
                    onClick={handlecreateticket}
                  >
                    Create Ticket
                  </Button>
                </Link>
              )}
            </div>
          </div>
        ) : (
          <article
            style={{
              paddingLeft: "25px",
              display: "flex",
              alignItems: "center",
              gap: 20,
              paddingTop: "10px",
              paddingBottom: "20px",
            }}
          >
            <h3>
              Open ticket :{" "}
              <span style={{ fontWeight: "bold", color: "#229849" }}>
                {ticketCount[0] && ticketCount[0].value}
              </span>{" "}
            </h3>
            <h3>
              Closed ticket :{" "}
              <span style={{ fontWeight: "bold", color: "#f72222" }}>
                {ticketCount[2] && ticketCount[2].value}
              </span>
            </h3>
          </article>
        )}
      </div>
      <div style={{ width: "92vw", padding: "0px 2px 0px 23px" }}>
        <Spin spinning={loading} indicator={<LoadingIndicator />}>
          <Table
            columns={columns}
            className="ticket-table"
            dataSource={
              filteredResults.length !== 0 || stateData === "true"
                ? filteredResults
                : ticket
            }
            rowSelection={{
              ...rowSelection,
            }}
            rowKey="ticket_id"
            showSizeChanger="true"
            pagination={{
              defaultPageSize: 10,
              pageSizeOptions: [5, 50, ticket.length],
            }}
            scroll={{
              x: 2400,
            }}
          />
        </Spin>
      </div>
    </>
  );
}

export default Index;
